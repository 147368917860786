// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import 'materialize-css/dist/js/materialize'
import "channels"
import "feeds-icons"

var jQuery = require('jquery')

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

$(function(){
  $("#fetch-topics").click(function(){
    $("#topics-sub-container").hide();
    $("#topics-loader").removeClass("hide-loader");
    
    // setTimeout(function() {
    //   $('.topic.input-field label').each(function() {
    //     $(this).addClass('active');
    //   });
    // }, 1000);
  });
  
  createIcons();
  
  // $(".dropdown-trigger").dropdown();
  //feed_topics_attributes_0_materials_icon
})
